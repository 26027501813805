@import '../common'
@import '../../../../styles/colors'

.partner
  section.hero--fancy
    border-bottom: 1px solid #f6f8fa
    position: relative
    padding-top: 40px
    h1
      font-size: 32px
    .voucher-deals
      display: inline-block
      padding: 10px 20px
      color: $voucher_blue
      background: #f4f9fe
      border-radius: 25px
      margin-top: 20px
    .logos
      height: 60px
      margin-bottom: 20px
      display: flex
      align-items: center
      justify-content: center

      .logo
        height: 40px

        &.etsy
          width: 110px
          padding-left: 25px
          margin-top: 5px

      span
        font-size: 40px
        margin: 0 20px

  section.brand
    border-top: 1px solid #f6f8fa
    border-bottom: 1px solid #f6f8fa
    margin: 0
    max-width: none
    h2
      text-align: center
      font-size: 28px
      font-weight: 600
      margin-bottom: 20px
    .content
      position: relative
      display: flex
      justify-content: space-between
      .lhs,.rhs
        width: 50%
        margin: 20px 0
        padding: 0 30px
      .rhs
        text-align: left
        p
          margin-bottom: 10px
          line-height: 2em
      .notice
        display: flex
        align-items: center
        padding-bottom: 20px
        .icon
          color: $voucher_blue
          background: #F4F9FE
          height: 100px
          min-width: 100px
          border-radius: 50%
          display: flex
          justify-content: center
          align-items: center

          .far, .fas
            font-size: 2.5em

        .text
          text-align: left
          padding-left: 24px

      h3
        font-size: 18px
        font-weight: 600
        padding-bottom: 5px
      p
        font-size: 16px
        color: #637381

@media screen and (max-width: $mobile_max)
  .partner
    section.hero--fancy
      form.search
        input::placeholder
          color: transparent
      .voucher-deals
        margin: 20px 10px

    section.brand
      padding-bottom: 20px
      .content
        flex-direction: column
        .lhs,.rhs
          width: 100%


