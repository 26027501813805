@import '../common'

$menu_width: 282px
$bg_grey: #2a333b

#mobile_menu, #your_account_menu, #miniheader
  display: none


@media screen and (max-width: $mobile_max)
  body
    position: relative
    -webkit-backface-visibility: hidden

  header, #main_nav
    display: none

  #miniheader
    display: block
    position: fixed
    top: 0
    left: 0
    z-index: 2
    background: #2a333b
    color: $white
    width: 100%
    height: 70px
    padding: 2px 5px

    .logo
      font: 30px Inter
      a.home img
        margin: 18px 0 0 5px
        width: 92px
        height: 27px

    .shopping_cart_menu
      position: absolute
      top: 14px
      right: 52px
      font-size: 22px
      height: 100%
      a
        color: $green

    .menu
      cursor: pointer
      position: fixed
      top: 24px
      right: 20px
      display: block
      float: right

      .line
        display: block
        height: 3px
        width: 20px
        margin-top: 3px
        background: $green

  #mobile_menu, #your_account_menu
    display: none
    position: fixed
    overflow: scroll
    top: 0
    right: 0
    left: 0
    width: 100%
    z-index: 3
    background: rgba(42,51,59, 0.9)
    color: $white
    height: 100%
    transition: transform 0.5s ease-in-out
    -webkit-transition: -webkit-transform 0.5s ease-in-out
    text-transform: uppercase
    padding-top: 20px

    button.mobile_menu_close
      position: absolute
      top: 5px
      right: 5px
      font: 16px Inter
      color: $white

    a.home, ul
      width: 66%
      margin: 0 auto
      padding: 10px 10px

    a.home
      display: block
      img
        width: 92px
        height: 27px

    ul
      text-align: center
      border-top: 1px solid #999

      &:last-of-type
        border-bottom: 1px solid #999

      li
        position: relative
        display: block
        list-style: none
        width: 100%
        font: 14px Inter
        color: $white

        & a:hover, &.title
          background-color: $green

        a, &.title
          display: block
          width: 100%
          height: 100%
          padding: 10px
          color: $white

        .more
          position: absolute
          top: 4px
          right: 5px

        .more, .back
          font: 24px Arial, Helvetica, sans-serif

        &.your_account
          font-size: 20px
          font-weight: 600
          padding: 10px 5px 10px

        &.contact
          span
            display: inline-block
            width: $menu_width - 135px
            height: 100%
            padding: 10px 5px 10px
            color: $white
          a
            display: inline-block
            padding: 10px 0 10px
            margin: 0
            width: 30px
            text-align: center
            i
              width: auto

  #mobile_menu
    right: 0
    &.your_account
      transform: translate(-$menu_width,0)
      -webkit-transform: translate(-$menu_width,0)
      -moz-transform: translate(-$menu_width,0)

  #your_account_menu
    right: -$menu_width
    display: none
    &.active
      display: block
      transform: translate(-$menu_width,0)
      -webkit-transform: translate(-$menu_width,0)
      -moz-transform: translate(-$menu_width,0)

  .blur
    filter: blur(5px)
    overflow: hidden

  main, footer, header
    transition: transform 0.5s ease-in-out
    -webkit-transition: -webkit-transform 0.5s ease-in-out

    &.slide_over
      transform: translate(-$menu_width,0)
      -webkit-transform: translate(-$menu_width,0)
      -moz-transform: translate(-$menu_width,0)

      &.double
        transform: translate(-$menu_width * 2, 0)
        -webkit-transform: translate(-$menu_width * 2, 0)
        -moz-transform: translate(-$menu_width * 2, 0)
