@use 'sass:math'

$mobile_max: 767px
$tablet_min: 768px
$tablet_max: 1024px
$desktop_min: 1025px

$page_max_width: 1280px


// TODO: delenda est
.mobile_only
  display: none
@media screen and (max-width: $mobile_max)
  .mobile_only
    display: default

// Clear floated elements: http://sonspring.com/journal/clearing-floats
%clear
  clear: both
  display: block
  overflow: hidden
  visibility: hidden
  width: 0
  height: 0

// https://css-tricks.com/snippets/css/clear-fix/
@mixin clearfix
  &:after
    content: ""
    display: table
    clear: both

%clearfix
  +clearfix

@mixin noclearfix
  &:after
    content: none

// Grid

.grid_container
  @extend %clearfix
  width: 100%
  max-width: $page_max_width
  margin: 0 auto
  padding: 0

@mixin grid($k, $n: 12)
  $percent: math.div($k * 100%, $n)
  @include clearfix
  float: left
  width: $percent
  padding-left: 10px
  padding-right: 10px


// %grid1 through %grid12
@for $num from 1 through 12
  %grid#{$num}
    +grid($num)

  $percent: math.div($num * 100%, 12)
  %pre#{$num}
    margin-left: $percent
  %post#{$num}
    margin-right: $percent

// %grid1_10 through %grid10_10
@for $num from 1 through 10
  %grid#{$num}_10
    +grid($num, 10)


// Debug mode
#gridoverlay
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  min-height: 100%
  opacity: .3
  background-color: #000

  .grid1
    @extend %grid1
  .x
    height: 100000px
    background: #fcc


@for $num from 1 through 12
  .col-sm-#{$num}
    width: 100%


@media screen and (min-width: 768px)
  @for $num from 1 through 12
    .col-sm-#{$num}
      +grid($num)

    .col-sm-offset-#{$num}
      margin-left: math.div($num * 100%, 12)

@media screen and (min-width: 1024px)
  @for $num from 1 through 12
    .col-md-#{$num}
      +grid($num)

    .col-md-offset-#{$num}
      margin-left: math.div($num * 100%, 12)

@media screen and (min-width: 1280px)
  @for $num from 1 through 12
    .col-lg-#{$num}
      +grid($num)

    .col-lg-offset-#{$num}
      margin-left: math.div($num * 100%, 12)


