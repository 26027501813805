@import '../common'

.home.whois
  .features
    margin-top: 30px

  .whois_comparison
    padding-top: 40px

    h1
      margin-bottom: 20px

  .example
    +grid(6, 12)

    .caption
      background: $black
      color: $white
      padding-top: 16px
      font-size: 12px
      text-transform: uppercase
      height: 45px

    .inner
      border: 1px solid $grey
      padding: 0 20px 20px 20px

    h2
      font-weight: 500
      margin: 20px 0 0

    dl
      +clearfix
      font: 500 14px "Source Code Pro", monospace
      margin-top: 20px
      border-top: 1px solid $grey
      padding-top: 20px

    dt, dd
      float: left
      height: 20px

    dt
      clear: both
    dd
      margin-left: 10px

    dl
      overflow: hidden

    .fade
      position: relative
    .fade .gradient
      position: absolute
      top: 0
      left: 0
      display: block
      content: " "
      width: 100%
      height: 100%
      background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0) 90%, rgba(255,255,255,1))

  .example.bad dd
    color: $status_amber
  .example.good dd
    color: $status_green


@media screen and (max-width: 767px)
  .home.whois
    .example
      float: none
      display: block
      max-width: 600px
      margin: 0 auto 20px
      width: 100%
