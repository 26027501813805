@import '../../../../styles/colors'

section.kill-token
  padding-top: 60px
  padding-bottom: 70px
  margin: 0 auto
  max-width: 580px
  h1
    border-bottom: 1px solid $grey_d6
    padding-bottom: 10px
    font-size: 42px
  p
    padding: 20px 0
  form
    max-width: 308px
    margin-left: auto
