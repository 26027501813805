// https://proto.io/freebies/onoff/
//
// .onoff_slide
//   %input#idxyz{type: "checkbox", checked: true}
//   %label{for: "idxyz"}
//
.onoff_slide
  position: relative
  width: 28px
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none

  input[type=checkbox]
    display: none

  $h: 18px

  label
    display: block
    overflow: hidden
    cursor: pointer
    height: $h
    padding: 0
    line-height: $h
    border: 2px solid #CCCCCC
    border-radius: $h
    background-color: #CCCCCC
    transition: all 0.1s ease-in
    &:before
      content: ""
      display: block
      width: $h
      margin: 0px
      background: #FFFFFF
      position: absolute
      top: 0
      bottom: 0
      right: 12px
      border: 2px solid #CCCCCC
      border-radius: $h
      transition: all 0.1s ease-in 0s

  input[type=checkbox]:checked + label
    background-color: #7FC24E
    border-color: #7FC24E
    &:before
      border-color: #7FC24E
      right: 0px

  &.required
    input, label
      cursor: default
    input[type=checkbox]:checked + label
      background-color: #dbebad
      border-color: #dbebad
      &:before
        border-color: #dbebad
        right: 0px
