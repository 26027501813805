@import '../common'
@import '../elements/buttons'

#email_app
  margin-top: 35px
  .loading
    opacity: 0.4

  form.signin
    margin-top: 10px
    border-top: 1px solid $grey
    padding-top: 10px

    p.instr
      text-align: left

    input
      +chunky
      display: block
      margin-top: 10px

    button
      +btn
      +btn-primary
      margin-top: 10px

    p.note
      font-size: 12px
      line-height: 16px
      margin-top: 10px
      a
        display: block

  .autocomplete
    position: relative
    width: 100%
    max-width: 560px
    margin: 0 auto
    input[type=text]
      +chunky

    button.open
      position: absolute
      top: 0
      right: 0
      vertical-align: top
      width: 40px
      height: 40px
      font-size: 28px
      text-align: center
      padding: 0
      i
        line-height: 24px
        vertical-align: top

    button.add_to_cart
      margin-top: 15px
      padding-top: 8px
      padding-bottom: 8px
      i
        margin-left: 6px
        font-size: 18px

    strong
      font-weight: 600

    $li_height: 45px
    ul
      position: absolute
      top: 40px
      left: 0
      width: 100%
      list-style: none
      -moz-box-shadow: 0px 13px 32px 0px rgba(0,0,0,0.5)
      box-shadow: 0px 13px 32px 0px rgba(0,0,0,0.5)
      background: $white
      // min-height: 5 * $li_height
      max-height: 5.5 * $li_height
      overflow-y: scroll
      &::-webkit-scrollbar
        display: none

    li
      position: relative
      height: $li_height
      background: $white
      color: $black
      border: 1px solid $grey
      border-bottom: none
      text-align: left
      &:hover
        background: lighten($grey, 10%)
      &:last-child
        border-bottom: 1px solid $grey
      &.cart
        &:after
          position: absolute
          top: 11px
          right: 11px
          content: "\f07a"
          color: #AAADB0
          font: normal 16px/1 "Font Awesome 6 Pro"
          font-weight: 900

    a
      display: block
      width: 100%
      height: 100%
      color: $black
      padding-top: 11px
      padding-left: 10px

.home.email
  img.sync
    margin: 30px auto 0
    width: 100%
    max-width: 460px

.no-flexboxlegacy #email_app .mailbox_type
  height: 545px

.mailbox_types
  display: flex // each mailbox_type has the same height

.mailbox_type
  display: flex
  flex-direction: column
  width: 33.3333%
  padding-left: 10px
  padding-right: 10px

  .title
    padding: 15px
    width: 100%
    background: $black
    color: $white
    text-transform: uppercase
    font-size: 12px
    em
      color: $lgreen
  .body
    flex: 1 // Take up remaining height
    padding: 20px
    padding-bottom: 0
    border: 1px solid $grey

    &.active
      border: 3px solid $lgreen
      padding: 18px
      padding-bottom: 0

  h2
    color: $black
    font-weight: 600
    margin: 0
    div
      line-height: 26px

  // .mailbox-price is used in view_cart
  .mailbox-price-prefix
    display: inline-block
    font-size: 24px
    font-weight: 600
    vertical-align: top
    margin-right: -4px

  .mailbox-price-amount
    display: inline-block
    font-size: 60px
    line-height: 48px
    font-weight: 600

  .mailbox-price-suffix
    display: inline-block
    font-size: 24px
    font-weight: 600
    vertical-align: bottom

  .mailbox-price-extra
    text-transform: uppercase
    font-size: 12px


  // .price is used in /email
  .price
    margin-top: 15px
    display: inline-block
    font-size: 60px
    font-weight: 600
    line-height: 48px
    letter-spacing: -4px
    position: relative
    &::before
      font: 600 24px 'Inter'
      content: "$"
      position: absolute
      top: -4px
      left: -12px
      margin: 0

  .caption
    font-size: 12px
    text-transform: uppercase

  .controls
    margin-top: 10px

  ul.mailbox_features
    +clearfix
    margin-top: 20px
    li
      text-align: left
      padding: 11px 0 12px
      float: none
      display: block
      border-top: 1px solid $grey
    strong
      font-weight: 600

  button.select
    width: 140px

@media screen and (max-width: $mobile_max)
  .home.email
    section
      +pad10

  #email_app
    .mailbox_types
      display: block

    .mailbox_type
      display: block
      float: none
      width: 100%
      margin-top: 20px
      &:first-child
        margin-top: 0

