@use 'sass:math'
@import '../common'
@import '../elements/buttons'

$fa_check: "\f00c"
$fa_square_o: "\f096"
$fa_check_square_o: "\f046"
$fa_times: "\f00d"
$fa_plus: "\f067"

$lgrey: #e5e5e5
$llgrey: #f2f2f2
$phgrey: #9a9696

@mixin row-desktop
  display: grid
  grid-template-columns: auto auto 40px
  width: 100%
  .name,.price,.cta
    display: flex
    align-items: center
  .name
    grid-column-start: 1
    flex-direction: column
    align-items: flex-start
    justify-content: center
  .price
    grid-column-start: 2
    text-align: right
    padding-right: 10px
    justify-content: flex-end
    align-items: center
  .cta
    grid-column-start: 3
    justify-content: flex-end

@mixin row-mobile
  grid-template-columns: auto 40px
  grid-template-rows: 25px 25px
  .name
    grid-column-start: 1
    grid-row-start: 1
  .price
    grid-column-start: 1
    grid-row-start: 2
    justify-content: flex-start
  .cta
    grid-column-start: 2
    grid-row-start: 1
    grid-row-end: 3
    align-items: center
  .tagline
    display: none


.lookup
  .tagline
    font-size: 12px
    padding-top: 5px
  .row
    padding: 0 20px
    &.free
      background: $grey_f9
    &:hover
      background: $llgrey
    &:last-child .row__inner
      border: none
    .row__inner
      padding: 10px 0
      border-bottom: 1px solid $lgrey
      @include row-desktop
      @media screen and (max-width: 480px)
        @include row-mobile
  .add_to_cart.inCart
    &:hover
      i
        line-height: 30px
  .slash
    padding: 0 2px

  .new-header
    background-color: $grey_f6
    .herop
      display: flex
      justify-content: space-between
      padding: 40px 10px
      .new-search
        padding-right: 10px
        width: percentage(math.div(8, 12))
      .new-minicart-holder
        padding-left: 10px
        width: percentage(math.div(4, 12))
      .new-minicart
        height: 60px
        display: flex
        justify-content: space-between
        align-items: center
        border: 2px solid #d6d6d6
        padding: 5px 20px
        font-weight: bold
        p:first-child
          color: #637381
          font-size: 14px
          display: flex
          align-items: center
          i
            margin-right: 10px
            font-size: 22px
        p:last-child
          color: $black
          font-size: 20px
      .new-minicart-holder:hover .new-minicart p:first-child
        color: $black
  .row
    padding: 0 20px
    &.free
      background: $grey_f9
    &:hover
      background: $llgrey
    &.inCart .add_to_cart
      &:hover
        i
          line-height: 30px
    &:last-child .row-lined
      border: none

    .tagline
      font-size: 12px
      padding-top: 5px

    .offer
      margin-bottom: 0

    &.free
      background: $grey_f9

  .catslide
    .category
      &.exact
        padding: 0 10px
        .heading
          display: none
        &.highlighted
          border: inherit
        .rows
          padding: 30px 20px
          border: 2px solid $grey

          &:hover
            background: $white
        .offer
          margin: 0
        .star
          position: relative
          top: -2px
  .exact-match
    padding: 0
    &.free
      background: $white
    .exact-match__inner
      display: flex
      justify-content: space-between
    .name
      font-size: 24px
      align-items: center
      > *
        vertical-align: middle
      .tagline
        padding-top: 5px
        font-size: 16px
        text-align: left
    .price
      max-width: 224px
      font-size: 24px
      display: flex
      justify-content: flex-end
      align-items: center
      margin-right: 10px
    .cta
      max-width: 190px
      display: flex
      justify-content: flex-end
      align-items: center
  .grouped_price_add
    display: flex
    justify-content: flex-end
    align-items: center
    white-space: nowrap
  .bundle
    margin: 0 10px
    padding: 20px
    background-color: $grey_fa
    border: 1px solid $grey
    border-top: none
    .bundle__header
      display: flex
      justify-content: space-between
      padding: 5px 0
      p
        text-transform: uppercase
        color: $dgrey
    .bundle__row
      border-bottom: 1px solid $lgrey
      padding: 10px 0
      @include row-desktop
      &:last-child
        border: none
        padding-bottom: 0
      &:hover
        background: $grey_fa
      .name
        font-size: 18px

  .add_to_cart
    color: $white
    display: block
    height: 30px
    width: 30px
    text-align: center
    background: $green
    border: none
    padding: 0

    &:hover
      background: $dgreen
    i
      font-weight: 900
      font-style: normal
      font-size: 18px

    span
      display: none

  .grouped_price_add
    display: flex
    align-items: center
    white-space: nowrap
  .btn.make-offer
    white-space: nowrap
  .exact-add-cart
    display: flex
    align-items: center
    padding: 9px 15px
    white-space: nowrap
    span
      margin-left: 5px
    i
      font-size: 19px
      font-weight: 900
      width: 25px
  h2
    font-weight: 500
    color: $black
  .extra-results
    display: flex
  .results-left
    width: 66%
  .results-right
    width: 33%
  .results-left
    padding: 0 10px
  .results-right
    padding-left: 10px
  .left
    +grid(8,12)

  .right
    +grid(4,12)

  form.search
    margin-top: 0

  form.search
    max-width: none
    input[type=text]
      max-width: none
      width: calc(100% - 60px)

  @include fade-slide(progress)
    max-height: 60px

  .search_heading
    min-height: 60px
    margin-bottom: 20px
    position: relative

    &.partner
      padding-left: 80px
      margin-bottom: 40px
      margin-top: -20px

    img
      position: absolute
      top: 2px
      left: 10px

    h1
      margin: 0
      margin-bottom: 5px
      padding: 0
      padding-top: 5px
      font-size: 22px
      font-weight: 400
      color: $black
      text-align: left

      &.variant-plain
        padding-top: 15px
  .offer
    color: $voucher_blue
    display: flex
    align-items: center
    margin-bottom: 30px
    i
      font-size: 26px
      margin-right: 10px

  .progress
    padding: 0 10px
    +transition(opacity 1s, max-height 0.5s)

    .status
      +caps
      margin-bottom: 4px

    .progress_bar
      width: 100%
      height: 20px
      background: #fff
      border: 1px solid #d4d6d8
      overflow: hidden

      > span
        +transition(width 0.5s)
        background: $green
        height: 100%
        display: block

    .space
      height: 20px

  .results
    .catslide
      +transition(all 500ms)
      overflow-y: hidden

    .category
      width: 100%

      .heading
        position: relative
        height: 45px
        width: 90%
        left: 20px
        padding-top: 17px

        h2
          +caps
          font-weight: 900
          color: $grey_63
          margin: 0

        strong
          color: $emph_orange

        img.logo
          position: absolute
          top: 3px
          left: 3px

      &.highlighted
        border: 2px solid $lgreen

        .heading
          background: $lgreen
          color: $black
    .rows
      border: 1px solid $lgrey

    .col
      vertical-align: top
      .name
        vertical-align: top
      .premium_star
        margin-right: 6px
        font-size: 16px
        color: $yellow
      .tiered_price_star
        @include tiered_price_star
        margin-right: 6px
        font-size: 16px
      .tagline
        vertical-align: baseline
        .tip
          vertical-align: baseline
      .sale
        vertical-align: top
        color: $white
        background: $emph_orange
        display: inline-block
        padding: 6px 6px 5px
        font: 8px Arial, Helvetica, sans-serif
        &.hidden
          display: none
      .featured
        color: $green
        margin-left: 6px
        vertical-align: top

      .call_us
        color: $green
        display: block
        font-size: 24px
        line-height: 32px
        height: 30px
        width: 30px
        text-align: center
        border: none
        padding: 0

      &.add
        text-align: center
        width: 40px

        .add_to_cart
          color: $white
          display: block
          height: 30px
          width: 30px
          text-align: center
          background: $green
          border: none
          padding: 0

          span
            display: none

          &:before
            font: normal 18px/32px "Font Awesome 6 Pro"
            font-weight: 900
            content: $fa_plus
    .taken
      +caps
      margin-top: 4px

    .message
      margin-bottom: 40px
      h1, h2
        margin: 0

    .tagline
      clear: both
      font-size: 12px
      line-height: 16px


  .filters
    h2
      position: relative
      width: 100px
      height: 45px
      left: 20px
      padding-top: 17px
      margin: 0
      color: $phgrey
      +caps

    form
      overflow-x: hidden
      padding: 20px
      border: 1px solid $lgrey

    label
      display: block
      line-height: 24px
      height: 24px
      white-space: nowrap

      i
        color: $green
        font-size: 20px
        width: 20px

      span
        margin-left: 5px
        display: inline-block
        vertical-align: top
        color: $black

.ReactModalPortal
  .closeButton
    margin: 0
    color: $mgrey
    font-size: 24px

  .content
    display: flex
    flex-direction: column
    align-items: center
    padding: 30px 20px
    .logo
      width: 200px
      margin-bottom: 30px
    p
      color: #637381


  .options
    margin-top: 24px
    display: flex
    justify-content: center

    button
      padding-left: 30px
      padding-right: 30px

  .loading
    opacity: 0.5

@media screen and (max-width: 768px)
  .lookup
    .bundle
      margin: 0
      padding: 20px 10px
      .bundle__header
        display: flex
        justify-content: space-between
        flex-direction: column
        *
          margin-bottom: 10px
        button
          text-align: right
      .bundle__row
        @include row-mobile
    .progress
      margin: 0 10px
    .new-header
      .herop
        padding: 0
        .new-search
          width: 100%
          padding: 0
        .new-minicart-holder
          display: none

    .exact-add-cart
      padding: 0
      height: 30px
      padding: 0 10px 0 5px
      i
        padding: 0

    .catslide
      .category.exact
        padding: 0
        .rows
          padding: 15px
          .exact-match
            .exact-match__inner
              .name
                font-size: 20px
    .results-left
      width: 100%
      padding: 0
      margin: 0
      border: 0
    .results-right
      display: none
    .left
      width: 100%
      padding: 0
      margin: 0
      border: 0

    .search_heading, .results
      padding-left: 20px
      padding-right: 20px

    .search_heading.partner
      margin-left: 20px

      h1
        line-height: 26px
      p
        display: none

      img
        left: 0

    form.search
      text-align: left

    .filters
      display: none

    .partner_heading
      min-height: 60px
      padding-left: 80px
      margin-bottom: 20px
      margin-left: 20px
      img
        left: 10px

    .offer
      margin-left: 20px
      padding-right: 20px

  @-webkit-keyframes slideup
    from
      height: 0
    to
      height: 45px

  @keyframes slideup
    from
      height: 0
    to
      height: 45px

@media screen and (max-width: 480px)
  .lookup
    .progress
      margin: 0
    form.search
      max-width: 100%
      margin: 0
      text-align: left
      input[type="text"]
        max-width: 100%
    .herop
      padding-top: 30px
    .no-mobile
      display: none
    .results
      padding: 0 10px
      .category
        .heading
          position: static
        &.exact .rows
          padding: 0

      .rows
        border-left: none
        border-right: none
        padding: 0
        .row
          padding: 0
          grid-template-columns: auto 40px
          grid-template-rows: 25px 25px
          .name
            grid-column-start: 1
            grid-row-start: 1
            padding-left: 10px
          .price
            grid-column-start: 1
            grid-row-start: 2
            justify-content: flex-start
            padding-left: 10px
          .cta
            grid-column-start: 2
            grid-row-start: 1
            grid-row-end: 3
            align-items: center
            padding-right: 10px
        .tagline
          display: none
        .exact-match
          .exact-match__inner
            flex-direction: column
            .name
              text-align: center
              padding: 20px
              .bold
                display: block
            .price
              font-size: 16px
              .promo_price .icon
                display: none
            .grouped_price_add
              justify-content: space-between
              padding: 10px 8px
              background-color: $grey_f6
          &.make-offer
            .grouped_price_add
              justify-content: center

      .col
        .featured
          display: none
  .ReactModalPortal
    .options
      display: flex
      justify-content: center
      align-items: center
      .continue
        margin-left: 0
      .cancel
        margin-left: 40px
