=chunky
  vertical-align: top
  height: 40px
  width: 100%
  border: 2px solid $grey
  font-size: 14px
  color: $black
  padding: 0 20px
  &::placeholder
    color: darken($grey, 30%)
    opacity: 1
    text-transform: uppercase
    font-size: 12px
  font-weight: 400
  font-size: 14px
  line-height: 16px
