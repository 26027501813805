.realnames
  .prompt, #realnames-form
    width: 100%
    max-width: 620px
    margin-left: auto
    margin-right: auto
  p.prompt
    margin-bottom: 20px
    font-size: 20px
  #realnames-form
    margin-top: 20px
    margin-bottom: 40px
    div
      display: flex
      flex-direction: column
      margin-bottom: 10px
      label
        margin-bottom: 5px
      input[type="text"]
        vertical-align: top
        height: 60px
        width: 100%
        border: 2px solid $grey
        font-size: 22px
        font-family: 'Inter'
        color: $black
        padding: 0 20px
  .result
    display: flex
    justify-content: space-between
    align-items: center
    padding: 20px
    border: 2px solid $lgrey
    width: 66%
    margin: 0 auto 10px

  .add-to-cart
    color: $white
    text-align: center
    background: $green
    border: none
    display: flex
    align-items: center
    padding: 9px 15px
    white-space: nowrap
    span
      margin-left: 5px
    i
      font-size: 19px
      font-weight: 900
      width: 25px
