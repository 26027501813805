@import '../common'

header
  position: relative
  height: 70px
  background: #2a333b

  div.logo
    @extend %grid2

    #logo
      display: block
      margin-top: 18px
      img
        width: 103px

  nav
    @extend %grid10
    text-align: right
    margin-top: 40px
    font: normal 13px 'Inter'

    li
      display: inline-block
      padding: 0
      height: 30px
      margin-left: 18px
      border-bottom: none
      &.active, &.hl:hover
        border-bottom: $underwidth solid $lgreen
        @include transition(border-bottom-width 0.5s)

    a
      display: block
      height: 100%
      color: $link_color
      text-transform: uppercase
      font-weight: 400
      &.active, &:hover
        color: $lgreen
