@import "css_reset"

// fa-font-path is no longer required as `resolve-url-loader` will take care of the relative path
// $fa-font-path: "../../vendor/styles/fa-pro/webfonts"
@import "../../vendor/styles/fa-pro/scss/fontawesome"
@import "../../vendor/styles/fa-pro/scss/regular"
@import "../../vendor/styles/fa-pro/scss/solid"
@import "../../vendor/styles/fa-pro/scss/brands"
@import "../../vendor/styles/fa-pro/scss/light"



@import "./2015/default"

@import "./2015/elements/alert"
@import "./2015/elements/aside_help"
@import "./2015/elements/box"
@import "./2015/elements/buttons"
@import "./2015/elements/creditcard"
@import "./2015/elements/debug"
@import "./2015/elements/features"
@import "./2015/elements/flash"
@import "./2015/elements/flex-grid"
@import "./2015/elements/footer"
@import "./2015/elements/header"
@import "./2015/elements/hover_gradient"
@import "./2015/elements/main_nav"
@import "./2015/elements/minicart"
@import "./2015/elements/mobile_menu"
@import "./2015/elements/modalbox"
@import "./2015/elements/onoff_slide"
@import "./2015/elements/payment_icons"
@import "./2015/elements/promo_price"
@import "./2015/elements/qtip"
@import "./2015/elements/search_form"
@import "./2015/elements/selectmenu"
@import "./2015/elements/service_icons"
@import "./2015/elements/tweets"

@import "./2015/pages/admin/status"
@import "./2015/pages/about"
@import "./2015/pages/confirm_token"
@import "./2015/pages/domain_pricing"
@import "./2015/pages/email"
@import "./2015/pages/homepage"
@import "./2015/pages/lookup"
@import "./2015/pages/partner"
@import "./2015/pages/renew"
@import "./2015/pages/signup"
@import "./2015/pages/thankyou"
@import "./2015/pages/tld"
@import "./2015/pages/tos"
@import "./2015/pages/transfer_in"
@import "./2015/pages/view_cart"
@import "./2015/pages/welcome"
@import "./2015/pages/whois"
@import "./2015/pages/realnames"

/**
 * @see https://stackoverflow.com/questions/26032089/in-html-how-can-i-have-text-that-is-only-accessible-for-screen-readers-i-e-fo
 */
.sr-only
  position: absolute !important // Outside the DOM flow
  height: 1px
  width: 1px // Nearly collapsed
  overflow: hidden
  clip: rect(1px, 1px, 1px, 1px)
