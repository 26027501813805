%svg-common
  background: url(../../../images/home/homepage/payment.svg) no-repeat

.payment-flat-amex
  @extend %svg-common
  background-position: 0 0

.payment-flat-amex-dims
  width: 40px
  height: 25px

.payment-flat-discover
  @extend %svg-common
  background-position: 0 100%

.payment-flat-discover-dims
  width: 39px
  height: 25px

.payment-flat-mastercard
  @extend %svg-common
  background-position: 100% 0

.payment-flat-mastercard-dims
  width: 40px
  height: 25px

.payment-flat-paypal
  @extend %svg-common
  background-position: 0 50%

.payment-flat-paypal-dims
  width: 40px
  height: 25px

.payment-flat-visa
  @extend %svg-common
  background-position: 100% 50%

.payment-flat-visa-dims
  width: 40px
  height: 25px
