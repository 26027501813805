@import '../common'

#main_nav
  background: $green
  width: 100%
  height: 52px
  padding: 0 10px 0
  overflow: visible

  nav
    @extend %grid12

  ul
    position: relative
    font-size: 16px
    width: 100%
    font-family: 'Inter'
    text-transform: uppercase
    padding: 0
    overflow: visible
    white-space: nowrap
    z-index: 2

    li:first-child
      padding-left: 0

    li
      float: left
      vertical-align: top
      height: 52px
      margin: 0 20px 0 0
      border: none

      &.active, &.hl:hover
        border-bottom: $underwidth solid $black
        @include transition(border-bottom-width 0.3s)

      &.user
        float: right
        right: 0
        top: 0
        margin: 0
        padding: 0

    a
      display: block
      height: 47px
      padding: 18px 0 29px
      color: $white
      &:hover, &.active
        color: $black

    li.user ul
      border: none
      overflow: visible

      li
        margin: 0 0 0 10px

      .render-credit
        color: $black
        font-weight: 500

      .user_nav_space
        width: 5px

      li#shopping_cart
        font-weight: normal
        font-size: 18px
        padding: 0
        color: $white
        padding-top: 16px
        margin-right: 10px
        &.active
          color: $black
        a
          padding: 0

      li.sign_in_action
        margin: 0 10px
        &:last-child
          margin-right: 0

      li#myAccount.active, li#myAccount:hover
        background: $green
        color: #fff
        border: none
        border-bottom: none

        width: 140px

        > a
          padding-bottom: 7px
          color: #fff
          font-weight: 400
          &:active
            color: #fff

        ul
          display: block

      li#myAccount
        width: 140px
        text-align: right
        position: relative

        a i.chevron
          width: 12px
          font-size: 12px
          font-weight: 600
          vertical-align: top
          line-height: 20px

        ul
          width: 150px
          position: absolute
          top: 40px
          left: 0
          text-align: left
          display: none
          list-style: none
          background: $green
          border: none
          border-top: none
          padding: 9px 0
          z-index: 1

          li
            display: block
            font: normal 12px 'Inter'
            font-size: 14px
            margin: 0
            padding: 0
            height: auto
            letter-spacing: normal

            a
              display: block
              color: #fff
              width: 142px
              height: 27px
              margin-bottom: -12px
              padding: 6px 15px 0
              margin: 0
              display: block
              border: none

              &:hover
                color: #2a333b
                white-space: nowrap

