@import '../common'
.signup
  width: 50%
  margin: 0 auto
  padding: 50px 0 75px

  .header
    text-align: center
    h1
      font-size: 42px
      font-weight: 600
      margin-top: 20px
      margin-bottom: 40px
.signup, .signin
  .footer
    height: 50px
    margin-top: 50px
    display: flex
    align-items: center
    justify-content: center

@media screen and (max-width: 768px)
  .signup
    width: 100%
    padding: 20px !important
    margin-top: 70px

    .header
      width: 216px
      margin: 0 auto
      h1
        font-size: 32px

    main
      margin-top: 0
