@import '../common'

// Input field with credit card icon attached.
.ccicons
  position: relative
  &::after
    display: none
    content: ""
    position: absolute
    top: 8px
    right: 8px
    z-index: 1
    background: url(../../../images/home/homepage/payment.svg) no-repeat

// Standalone credit card icon
.cc-logo
  display: inline-block
  width: 40px
  height: 25px
  &::after
    display: block
    content: ""
    z-index: 1
    background: url(../../../images/home/homepage/payment.svg) no-repeat


.cc-type-american-express, .cc-type-amex
  &::after
    display: block
    background-position: 0 0
    width: 40px
    height: 25px

.cc-type-discover
  &::after
    display: block
    background-position: 0 100%
    width: 39px
    height: 25px

.cc-type-master-card
  &::after
    display: block
    background-position: 100% 0
    width: 40px
    height: 25px

.cc-type-visa
  &::after
    display: block
    background-position: 100% 50%
    width: 40px
    height: 25px

.cc-valid
  &::before
    content: "\f00c"
    font: 18px "Font Awesome 6 Pro"
    font-weight: 900
    position: absolute
    top: 12px
    right: 60px
    color: $status_green
    z-index: 2
