li, .feature
  .hover_gradient
    margin: 0 auto
    img
      width: 100%
      height: auto

  &:hover
    .hover_gradient
      position: relative
    // What is this witchcraft?
    // http://www.mademyday.de/css-height-equals-width-with-pure-css.html
    .hover_gradient:before
      content: ""
      display: block
      padding-top: 300%/166
      position: absolute
      top: 0
      right: 0
      bottom: 6px
      left: 0
      background-image: linear-gradient(to right,#33bb99,#c1da5a)
      opacity: .3
