.modalBox
  background: black
  padding: 20px 40px 40px 40px
  position: absolute
  display: none
  border-radius: 4px
  box-shadow: 0px 0px 30px 5px black
  z-index: 100

.modalBoxOverlay
  position: absolute
  top: 0
  left: 0
  background: rgba(0, 0, 0, 0.5)
  z-index: 50

.modalBox.light
  background: white
  color: black

.closeButton
  overflow: hidden
  height: 20px
  width: 20px
  float: right
  display: block
  margin: -10px -28px

.closeButton img
  margin: -193px 0 0 -2px
