html, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video
  margin: 0
  padding: 0
  border: 0
  font-size: 100%
  vertical-align: baseline
  font: inherit
  line-height: 1.33em

html
  box-sizing: border-box
*, *:before, *:after
  box-sizing: inherit

body
  line-height: 1
  margin: 0
  padding: 0
  border: 0
  font-family: 'Inter'
  font-size: 100%
  font-weight: 300
  vertical-align: baseline

a
  text-decoration: none

ol, ul
  list-style: none

blockquote, q
  quotes: none

blockquote:before, blockquote:after,
q:before, q:after
  content: ''
  content: none

table
  border-collapse: collapse
  border-spacing: 0

// HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section
  display: block

// Disable Chrome's blue border on active inputs
input, button
  outline: none

// Chrome v62 adds border-radius: 4px by default on buttons in OS X.
// This can probably be removed safely after June 2018.
button
  border-radius: 0

// Disable text box rounded corners and styling in iOS
input[type=text], input[type=password],input[type=email]
  border-radius: 0
  -webkit-appearance: none

strong, b
  font-weight: 600

// Disable Firefox border on buttons
// http://stackoverflow.com/a/5518143
button::-moz-focus-inner
  padding: 0
  border: 0

// Disable Firefox border on select menus
// http://stackoverflow.com/a/18853002
select:-moz-focusring
  color: transparent
  text-shadow: 0 0 0 #000

// Disable "clear field" indicator on IE10+
// http://stackoverflow.com/a/14007839
input::-ms-clear
  display: none

// Hide <select> drop-down arrow on IE10+
// http://stackoverflow.com/a/20163273
select::-ms-expand
  display: none
