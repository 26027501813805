=fade-slide($name)
  .#{$name}
    overflow-y: hidden
    @content

    &.#{$name}-enter
      opacity: 0
      max-height: 0
      margin-top: 0
      margin-bottom: 0
      padding-top: 0
      padding-bottom: 0
      &.#{$name}-enter-active
        opacity: 1
        @content

    &.#{$name}-exit
      opacity: 1
      @content
      &.#{$name}-exit-active
        opacity: 0
        max-height: 0
        margin-top: 0
        margin-bottom: 0
        padding-top: 0
        padding-bottom: 0

