@import '../common'

.tld_lander
  .logo
    margin-bottom: 30px

  form.search
    margin-top: 20px

  p.long_description
    margin-top: 30px
    @extend %pre1
    @extend %post1

  .details
    width: 100%
    max-width: 480px
    margin: 50px auto 0

    h2
      width: 100%
      background: $mgrey
      color: $black
      font-weight: 500
      height: 45px
      padding-top: 15px
      +caps

    p
      text-align: left
      padding: 10px
  
  ul
    width: 100%

  li
    +clearfix
    height: 45px
    border-bottom: 1px solid $mgrey
    padding: 15px 10px 0

    .label
      float: left
      font-weight: 500
      +caps

    .value
      float: right
