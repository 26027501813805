@import './common'

body
  background: $white
  font: 400 16px 'Inter'
  &.relative-body
    position: relative

main
  background: $white

a
  color: $green
  &:hover
    color: $black

.rgt
  text-align: right

.hidden
  display: none

.home
  text-align: center

.loading
  opacity: 0.4

.semi-bold
  font-weight: 500

.bold
  font-weight: 600

.pointer, .fa-clipboard
  cursor: pointer

.inline-block
  display: inline-block

.flex
  display: flex
  flex-flow: row nowrap

.flex-grow
  flex-grow: 1

.justify-center
  justify-content: center

.justify-between
  justify-content: space-between

.align-self-end
  align-self: end

.items-center
  align-items: center

input:focus
  @include placeholder
    transition: opacity 0.5s ease
    opacity: 0

section
  @extend %clearfix
  // -12px to counterbalance the line height on [section h1]
  padding: $section_padding - 12px 0 $section_padding 0
  border-bottom: 1px solid $grey
  min-width: 320px
  max-width: 1180px
  margin: 0 auto

  h1
    font-size: 32px
    font-weight: 600

  h1 + h2
    margin-top: 10px
    font-size: 18px
    line-height: 1.3em
    font-weight: 300
    color: $black

  h2
    margin-top: 16px
    font-size: 22px
    font-weight: 400
    color: $black

  em
    color: $emph_orange

  .read_more
    @extend %grid12

section.hero, section.main
  padding-top: 60px
  h1
    font-size: 42px
  > h2
    font-size: 24px

  .features
    margin-top: 50px

section.main
  h1
    margin-bottom: 40px
    text-align: center

section:last-child
  border-bottom: none

// Line break hinting. Use with linebreaks helper.
span.b
  display: inline-block

.price.discounted
  .reg
    text-decoration: line-through
  .now
    color: $emph_orange

.widebg
  .grid_container
    max-width: none
  #main_nav .grid_container
    max-width: 1280px

.admin_view
  display: block
  height: 45px
  background: #d4d6d8
  color: #2a333b
  text-align: center
  padding: 15px

@media screen and (max-width: $mobile_max)
  section
    width: 100%
    margin: 0 auto

    h1, h2
      padding-left: 10px
      padding-right: 10px

    h1, h1 .b
      line-height: 1.05em

  .read_more
    margin-top: 40px

  .not_mobile
    display: none


@media screen and (min-width: $tablet_min)
  section
    margin: 0 50px

@media screen and (min-width: $page_max_width)
  section
    margin: 0 auto
