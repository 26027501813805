@import '../common'

div.debug
  margin-top: 20px


pre.debug
  padding: 10px
  background: $black
  color: #fff
  font-family: "Lucida Console", Monaco, monospace
