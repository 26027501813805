@import '../common'

.home.thankyou
  h1
    line-height: 1.1em

  h2
    margin-top: 20px
    margin-bottom: 20px

  ul
    @extend %pre3
    @extend %post3
    @extend %clearfix
    border-top: 1px solid $grey

  li
    @extend %clearfix
    border-bottom: 1px solid $grey
    min-height: 45px

  .label
    float: left
    margin-left: 10px
    margin-top: 12px
  .action
    margin-top: 2px
    float: right

  .next_step
    margin-bottom: 30px

  .premium_warning
    @extend %clearfix
    display: block
    @extend %pre1
    @extend %post1
    border: 2px solid $lgreen
    padding: 10px

  .premium_star
    float: left
    display: block
    width: 40px
    text-align: center
    font: normal 30px Arial
    margin-right: 10px
    color: $yellow

  .note
    overflow: hidden
    text-align: left

@media screen and (max-width: 480px)
  .home.thankyou
    .label
      float: none
    .action
      float: none
      margin-bottom: 20px

