@import '../common'

.promo_price,.custom_price
  color: $emph_orange
  .icon
    background: $emph_orange
    color: $white
    text-decoration: none
    font-size: 10px
    padding: 1px 7px
    margin-right: 5px
    vertical-align: middle
.voucher_price
  color: $voucher_blue
  i
    margin-right: 5px
.regular_price
  color: $grey_63
.strike-through
  text-decoration: line-through
.strike-through,.slash
  color: $dgrey
