@import "../common"

.right aside.help
  margin-top: 20px

  .fas, .far
    vertical-align: top
    flex-grow: 0
    width: 28px
    font-size: 20px
    color: $grey_c1

    &.fa-clock
      line-height: 28px

  li.hours
    padding: 10px 0

  .status
    +caps

  .hours
    vertical-align: top
    display: inline-block

  .days
    margin-top: 6px
    +caps
