@import '../common'

.home.welcome
  h3
    font-size: 30px
    font-weight: 600
    margin-bottom: 1em

  section
    padding: 60px 20px
    margin: 0 auto
    max-width: 1280px
    border: none

  section.hero
    padding: 0 20px
    max-width: none
    margin: 0
    background: $black
    color: $white

    .bg
      padding: 80px 0 90px
      position: relative
      width: 100%
      height: 100%
      z-index: 2
      background-size: cover
      background-position: top center

      &::after
        content: ""
        opacity: 0.9
        position: absolute
        background: $black
        top: 0
        left: 0
        bottom: 0
        right: 0
        width: 100%
        height: 100%
        z-index: -1
        overflow: hidden

    .hiphop
      background-image: url("https://hover-assets.s3.ca-central-1.amazonaws.com/images/hiphop-graffiti-bg_optimized.jpg")
      z-index: inherit
      h1, p
        text-shadow: 2px 2px 5px $black, -2px -2px 5px $black

    h1, h2
      font-size: 36px
      line-height: 1.3em
      font-weight: 600
      color: $white

    h1
      margin: 0 auto

    h2
      margin: 0 auto 30px

    p
      font-weight: 400
      font-size: 24px
      margin: 0 auto 40px

  .vcenter
    display: flex
    flex-direction: column
    align-items: center

  section.easy
    h3
      margin-top: 40px
      margin-bottom: 40px

  section.full
    padding: 0
  .intro
    text-align: left

    ul
      list-style: disc
      margin-left: 20px
      font-size: 18px

    li
      margin-bottom: 1em

  .love
    margin: 0
    max-width: none
    background: #f0f0f0

    .vcenter
      display: flex

    .width
      max-width: 1280px
      margin: 0 auto

    .white-box
      padding: 20px 0

    .media-object
      @extend %clearfix
      padding: 20px 10px
      text-align: left
      .media-left
        float: left
        margin-top: 10px
        margin-left: 10px
        margin-right: 20px
        img
          display: block
      .media-body
        overflow: hidden
        zoom: 1
      &.welcome
        border: 2px solid $white

    h4
      white-space: nowrap
      font-weight: 400
      font-size: 18px
      margin-bottom: 10px

    p
      font-size: 14px

  input::placeholder
    visibility: hidden

  .hiphop-body
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100%
    margin: 0 auto
    .lookup
      width: 100%
      .results
        margin: 40px auto 0
        color: $black
        .catslide
          background: $white
          .category.exact
            padding: 0
    img
      height: 200px
      margin: 25px
      margin-bottom: 0


@media screen and (min-width: 768px)
  .home.welcome
    input::placeholder
      visibility: visible

    .love .row > :first-child .white-box
      margin-right: 10px
    .love .row > :last-child .white-box
      margin-left: 10px

    section.hero
      h1, h2
        font-size: 52px

@media screen and (min-width: 1024px)
  .home.welcome
    .vcenter
      flex-direction: row
      height: 100%
    section.easy
      h3
        margin-top: 0

    .hiphop-body
      max-width: 1024px

@media screen and (min-width: 480px)
  .home.welcome
    .love .white-box
      h4
        font-size: 24px
      p
        font-size: 16px

  .hiphop-body
    flex-direction: row
