@import '../common'

.ReactModal__Body--open
  // Prevent scrolling when modal is open
  overflow: hidden

.view_cart
  .left
    width: 100%
    padding: 0
    margin: 0
    border: 0

  .box ul li.hours .boxitem
    padding: 0 20px

  .box ul li .boxitem
    flex-direction: row
    align-items: center
    padding: 15px 20px 10px
    position: relative

    .quantity
      justify-content: flex-end

    .amount
      width: 92px
      text-align: right

    .term
      line-height: 0
      vertical-align: top
      .selectbox
        width: 100%
        height: 40px

  .box ul li.whois
    padding-bottom: 15px


  .email_options_popup
    text-align: center

    .modal-title
      font-size: 28px
      margin: 20px 0 30px

    ul
      font-weight: 400
      strong
        font-weight: 600

  .show_email
    i
      vertical-align: middle
      font-size: 16px
      margin-right: 6px

    span
      display: inline-block
      padding-top: 4px
      vertical-align: middle

  li.instant_savings
    .label
      width: 100%
    .boxitem-ie
      .boxitem
        padding: 15px 0 10px

    &::after
      border-bottom: 0

  form.validate
    .text_field.error
      width: 100%

    .text_field > input
      width: 100%
      max-width: 300px
      border-right-width: 1px

  aside.help
    display: none

  section.main
    padding-top: 45px

    h1
      margin-bottom: 24px

  .box li
    .label
      flex-grow: 1
      text-align: left

    .boxitem > div
      .selectbox, .onoff_slide
        margin-top: -4px


    .quantity
      display: flex
      flex-direction: row
      align-items: center
      justify-content: flex-end
      width: 180px
      span
        vertical-align: top
      .selectbox
        margin-left: 10px
        display: inline-block
        width: 80px

      .static-field
        display: inline-block
        width: 80px
        height: 40px
        padding-top: 8px
        padding-left: 10px
        margin-left: 10px

    &.personal
      .term
        width: 190px

    &.toggle
      justify-content: flex-start

    &.auth_code
      div.auth_code
        padding: 10px 0

        form
          margin: 15px 0 10px
        button
          margin: 0

    &.promo
      +caps
      font-weight: bold

    &.mbox .boxitem
      padding-left: 40px

    &.personal.ro .quantity
      text-align: right

    &.merged
      &::before, &::after
        border-bottom-color: transparent

    &.instant_savings
      color: $emph_orange
      .label
        text-align: right

    &.checkout > .boxitem-ie > .boxitem
      padding-top: 20px
      padding-bottom: 30px
      display: block
      text-align: right

      .payment_icons
        vertical-align: top
        padding-top: 9px
        display: inline-block

        svg
          margin-left: 4px

      button
        margin-left: 20px
        vertical-align: top
        white-space: nowrap

      .note
        margin-top: 10px
        font-size: 12px

  .highlight
    margin-left: 10px
    margin-right: 10px
    border-left: 1px solid $lgreen
    border-right: 1px solid $lgreen

  .highlight-first
    border-top: 1px solid $lgreen

  .highlight-note
    margin-left: 10px
    margin-right: 10px
    text-transform: uppercase
    font-size: 12px
    background: $lgreen

  .total
    margin-top: 9px
    font-weight: 600
    margin-left: auto

  .sc
    +caps

  .premium_star
    margin-left: 6px
    color: $yellow
  .tiered_price_star
    @include tiered_price_star

  .box .term .note
    display: block
    font-size: 12px
    line-height: 20px
    margin-bottom: -10px

  .claims
    top: 40px
    left: 0
    right: 0
    margin: 0 auto
    border: 10px solid $black
    padding: 20px
    box-shadow: 0px 0px 30px 5px $black
    height: auto
    width: calc(50% + 40px)

  button.remove_promo
    display: inline-block
    vertical-align: top
    margin-left: 6px

  .auth_code
    form.auth_code .confirmed input
      border-right-width: 1px


  // ================== Form validation =================
  form.validate
    position: relative
    display: block
    width: 100%

    input.error, input.error:focus
      border-color: $invalid_red

    input:focus
      border-color: $black

    .text_field, button
      display: inline-block
      border-width: 1px
      vertical-align: top
      height: 40px

    .text_field > input
      border-width: 1px
      border: 1px solid $grey
      border-right-width: 0
      height: 40px
      width: 300px
      font: 400 16px 'Inter'
      padding: 0 10px


    .text_field
      position: relative

      &.error > input
        border-color: $invalid_red

      &.confirmed::after
        // checkmark
        content: '\f00c'
        color: $status_green
        position: absolute
        font: normal 16px "Font Awesome 6 Pro"
        font-weight: 900
        top: 11px
        right: 10px

    input[type=text], input[type=email], input[type=password]

      &.error
        border-color: red

      &.confirmed::after
        // checkmark
        content: '\f00c'
        color: $status_green
        position: absolute
        font: normal 16px "Font Awesome 6 Pro"
        font-weight: 900
        top: 1px
        right: 1px

.results
  h1
    text-align: center

  .left
    +grid(8,12)
  .right
    +grid(4,12)

  .box
    overflow-y: hidden
    margin-bottom: 20px
    position: relative

    button.close
      +btn-link
      position: absolute
      top: 0
      right: 0
      width: 45px
      height: 45px
      font-size: 16px
      color: #2B333B
      i
        line-height: 0
      &:hover
        color: $invalid_red


  .right
    .minicart
      margin-bottom: 20px

    aside.help
      margin: 0

  button.btn-link
    +caps

.modal-close
  float: right

.modal-content
  font: 400 16px 'Inter'
  margin-bottom: 20px
  p
    margin-bottom: 10px
  h2
    font: 600 20px 'Inter'
    margin-bottom: 10px
  ul
    font: 600 16px 'Inter'
    margin-bottom: 10px



@media screen and (min-width: 960px)
  .view_cart
    .left
      +grid(8, 12)

    aside.help
      display: block
      margin-top: 0
@media screen and (max-width: 480px)
  .view_cart
    section.main
      padding-top: 24px
      h1
        font-size: 24px
        margin-bottom: 24px
    form.validate
      .text_field > input
        width: 100%

    .box ul li.whois
      padding-bottom: 5px
