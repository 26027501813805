@import '../common'

$arrow: 6px
.home.transfer_in
  form
    margin-top: 30px

.transfer_in.results
  .box
    margin: 10px 0 20px 0

    &:first-child
      margin-top: 0

    .tiered_price_star
      @include tiered_price_star
    ul.steps
      li
        display: block
        padding: 0 20px 0 40px
        .condition_status
          position: absolute
          top: 0
          left: 40px
          font-size: 20px
          > span
            display: none

        .condition_text
          margin: 10px 0 10px 30px

        .transfer_help
          float: right
          margin-top: 10px

        .condition.started .started
          display: inline
          color: $status_amber

        .condition.working .working
          display: inline
          color: $status_red

        .condition.completed .completed
          display: inline
          color: $status_green

        .renewal_price
          display: flex
          justify-content: space-between
          margin: 10px 0
          span
            display: inline-block
            &:last-child
              text-align: right


    .transfer_buttons
      padding: 20px 20px 0

      button
        margin-bottom: 20px

  .box.notEligible
    ul.steps > li
      &::before, &::after
        border: 0
    .transfer_buttons
      display: none

  .remind_me
    margin-top: 10px
    .btn-link
      +caps

      &:first-child
        padding-left: 0

  .domain .sub, ul.steps .status
    font-size: 12px
    line-height: 15px

  .transfer_buttons button
    display: none

  .transfer_group.notEligible .transfer_buttons button.valet_transfer,
  .transfer_group.notEligible .transfer_buttons button.refresh_status,
  .transfer_group.working .transfer_buttons button.valet_transfer,
  .transfer_group.working .transfer_buttons button.refresh_status,
  .transfer_group.ready .transfer_buttons button.add_to_cart
    display: inline-block

  .transfer_group.ready.incart .transfer_buttons button.add_to_cart
    display: none

  .transfer_group.ready.incart .transfer_buttons button.added_to_cart
    display: inline-block

.transfer_in
  button.search.primary
    > *
      position: absolute
      right: 15px

    .tline, .bline
      width: 23px
      height: 4px
      background: white

    .tline
      top: 20px
      right: 18px

    .bline
      top: 30px
      right: 13px

    .rtri
      top: 16px
      right: 13px
      width: 0
      height: 0
      border-top: $arrow solid transparent
      border-bottom: $arrow solid transparent
      border-left: $arrow solid white

    .ltri
      top: 26px
      right: 35px
      width: 0
      height: 0
      border-top: $arrow solid transparent
      border-bottom: $arrow solid transparent
      border-right: $arrow solid white

.foa_request
  font-weight: 400
  width: 840px
  margin: 0 auto

  h1
    font-weight: 500
    line-height: 30px
    margin-bottom: 6px

  p
    line-height: 18px
    margin-bottom: 10px

  ul
    list-style: disc
    margin-left: 20px

  form#foa
    background: $mgrey
    padding: 20px
    margin: 10px 0

    p.initials
      display: flex
      align-items: center
      input[type=text]
        +chunky
        margin-left: 6px
        border-right: none
        width: 100px

    p:last-child
      margin-bottom: 0



.modalBox.transfer_in
  form.submit
    .sending
      margin: 8px 0 0 9px

    .sent
      color: #6eaf37
      i
        font-size: 18px
        margin: 7px 0 0 9px
        display: inline-block

    button
      vertical-align: top
      height: 29px
      padding: 0 10px


  &.notify_me form label.error
    margin: 0 4px

  &.email .email, &.sms .sms
    display: inline-block

  .fieldset
    background: none repeat scroll 0 0 #eee
    margin: 14px 0
    padding: 20px 0 20px 20px

    input
      vertical-align: top
      height: 29px
      font-size: 14px
      border: 1px solid $grey

    .selectmenu
      vertical-align: top
      height: 29px
      font-size: 14px
      &:after
        top: 11px

      width: 146px
      display: inline-block
      overflow: hidden
      padding: 2px 0 0 10px
      select
        width: 127%


  background: none repeat scroll 0 0 #FFFFFF
  color: #6F7A82

  h1
    margin: 14px 0

  .sms, .email
    display: none

  input[name="email"]
    width: 200px

@media screen and (max-width: 1023px)
  .transfer_in.results
    .left
      .results
        .selectmenu
          display: none

@media screen and (max-width: $mobile_max)
  .transfer_in.results
    .left
      width: 100%
      padding: 0
      margin: 0
      border: 0

      .results
        .selectmenu
          display: none
        .status
          position: absolute
          top: 13px
          left: 20px
        .reason
          padding-top: 12px
          padding-bottom: 11px
          margin-left: 30px

    aside.help
      display: none
