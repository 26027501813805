@import '../common'

.home.renew
  button.primary
    font-size: 32px
    line-height: 0
    padding-left: 1px

  form
    margin-top: 30px

.results.renew_results
  .renewable, .not_renewable
    .status
      vertical-align: top
      font-size: 22px
      margin-right: 8px

    .reason
      display: inline-block
      vertical-align: top

  .renewable
    .selectmenu, .price
      text-align: right

    .price
      width: 60px

    .tools
      display: flex
      flex-direction: row
      align-items: center

    button.add
      margin: 20px 0

  .renewable .status
    color: $status_green

  .not_renewable .status
    color: $status_amber

  .info.sub
    padding-left: 30px

  .selectmenu
    height: 40px
    width: 140px
    select
      height: 100%
      padding: 0
      padding-left: 14px
      line-height: 40px
    &:after
      top: 0
      right: 0
      height: 40px
      width: 40px
      border-left: 1px solid $grey
      padding: 18px 14px
      content: '\25be'
      font-size: 24px


  .left
    form.search
      margin-bottom: 20px
      button
        line-height: 100%

.ui-autocomplete.renew
  padding: 0
  border-radius: 0

  .ui-menu-item
    height: 45px
    border-top: 1px solid $grey
    border-radius: 0
    cursor: default

  a
    background: none
    display: block
    height: 45px
    line-height: 40px
    border-radius: 0
    padding-left: 20px
    font-size: 16px
    color: black
    border: none

  .ui-menu-item:hover a, a.ui-state-hover, a.ui-state-focus, a.ui-state-active
    background: $lgrey
    border: none
    margin: 0

.ui-autocomplete.renew.results
  a
    font-size: 14px


@media screen and (max-width: 1023px)
  .results.renew_results
    .left
      .results
        .selectmenu
          display: none

@media screen and (max-width: $mobile_max)
  .results.renew_results
    .left
      width: 100%
      padding: 0
      margin: 0
      border: 0

      .results
        .selectmenu
          display: none
        .status
          position: absolute
          top: 13px
          left: 20px
        .reason
          padding-top: 12px
          padding-bottom: 11px
          margin-left: 30px

    aside.help
      display: none
