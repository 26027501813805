@import '../common'

$pink: #ffe5e5

.hover.qtip
  width: 200px
  background: $black
  color: white
  font-size: 12px
  text-align: left
  border: 7px solid $black

.hover.qtip.orange
  background: $emph_orange
  border: 7px solid $emph_orange

.hover.qtip.pricing
  background: $black
  border: 7px solid $black

.hover.status_tip.qtip
  width: auto

.hover.google.qtip
  width: 200px
  background: $emph_orange
  border: 7px solid $emph_orange
  text-transform: uppercase
  font: normal 13px 'Inter'

.hover_error.qtip
  width: 350px
  background: $pink
  line-height: 18px
  border: 1px solid $red
  font-size: 12px
