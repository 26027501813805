.home.about
  .big_ideas
    img
      display: block
      margin: 0 auto

    .features .flex-col
      // This is a workaround for a Safari bug that
      // makes the stories appear in one very wide row.
      flex: 1 1 360px
