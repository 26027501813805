=btn
  font: 600 12px 'Inter'
  text-transform: uppercase
  background: $white
  border: 2px solid $green
  padding: 9px 20px 10px
  color: $green

  span
    -webkit-font-feature-settings: normal

  &:hover:not(:disabled)
    color: $white
    background: $dgreen
    border-color: $dgreen


  &:disabled
    opacity: 0.5
    cursor: default

  &:focus
    outline: 0


=btn-primary
  color: $white
  background: $green

  &:focus
    border: 2px dashed $lgreen

  &:hover:not(:disabled)
    color: $white
    background: $dgreen
    border-color: $dgreen

=btn-danger
  color: $red
  background: $white
  border-color: $red
  &:hover:not(:disabled)
    color: $white
    background: $red
    border-color: $red

=btn-link
  display: inline
  background: none
  border: none
  padding: 0
  color: $link_color
  font-family: 'Inter'
  font-size: 1em
  line-height: 100%
  &:focus
    outline: 0
  &:hover
    color: $button_hover
    cursor: pointer
