@import '../common'
#flash_bar

  &.notice
    background: #d8f3ec
    border-bottom: 1px solid $grey

  &.alert
    background: #f3d8d8
    border-bottom: 0

  .message, .close
    padding-top: 18px
    padding-bottom: 16px
  .message
    +grid(11,12)
    &.fullwidth
      +grid(12,12)
  .close
    +grid(1,12)
    text-align: right
