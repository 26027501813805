@import '../common'

footer.primary
  background: $black
  color: $white
  padding: 70px 0
  text-align: center

  nav
    display: none

  form
    input[type=text]
      border: 2px solid white

  ul.social
    margin-top: 20px
    li
      font-size: 18px
      display: inline-block
      vertical-align: top

    .fa-inverse
      color: $black

    li.gplus
      width: 2em
      height: 2em
      padding: 4px
      a circle
        fill: $green
      a:hover circle
        fill: $lgreen
      a svg
        display: inline-block
        line-height: 2em
        height: 100%
        width: 100%


footer.copyright
  margin-top: 30px
  div.copyright, div.payment_logos
    text-align: center

  div.payment_logos
    margin-top: 12px
    svg
      display: inline-block
      margin-left: 4px


@media screen and (min-width: 768px)
  footer.primary

    a:hover
      color: $lgreen

    nav
      li
        display: inline-block
        vertical-align: bottom
        padding: 20px 8px

      a
        text-transform: uppercase
        font-size: 13px

  footer.copyright
    background: $white
    color: $black
    padding: 20px 0
    font-weight: 400
    font-size: 14px

    ul
      li
        display: inline-block
        vertical-align: bottom
        padding-right: 27px

  div.payment_logos
    text-align: right
    padding-right: 10px

@media screen and (min-width: 1024px)
  footer.copyright
    max-width: 1280px
    margin: 0 auto
    padding: 20px
    +clearfix

    div.copyright
      float: left
      text-align: left
      li
        vertical-align: middle
        line-height: 40px

    div.payment_logos
      float: right
      text-align: right
      margin-top: 7px
