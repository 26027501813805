@import '../common'

.box
  width: 100%
  border: 1px solid $grey_f0
  text-align: left
  color: $black

  h2
    display: flex
    align-items: center
    margin: 0
    line-height: 100%
    height: 45px
    background: $grey_ea
    font-weight: 500
    padding: 0 20px
    +caps
  p
    padding: 15px 0 10px
    margin: 0 20px
    border-bottom: 1px solid $grey_d6
  ul
    &.push-right
      li
        padding-left: 20px
        margin: 0

    li > .boxitem-ie
      display: flex

    li > .boxitem-ie > .boxitem
      display: flex
      width: 100%
      align-items: center
      justify-content: space-between
      min-height: 45px
      padding: 0 20px

    // The borders and heights are weird:
    //   - We want to measure 45px from top of cell to bottom of bottom border,
    //     which means 44px vertical white space.
    //   - We don't want the bottom border to go all the way across.
    //   - We want flexbox to center things according to the visible space.
    //
    // So the bottom line of the <li> has:
    //   - a transparent border on the <li> itself to maintain the interior height
    //   - two visible half-width borders, overlapping in the middle, and stopping 20px from either edge.
    li
      position: relative
      border-bottom: 1px solid transparent
      &:before, &:after
        content: ""
        position: absolute
        bottom: -1px
        height: 1px
        width: 50%
        border-bottom: 1px solid $grey_f0

      &:before
        left: 20px

      &:after
        right: 20px

      &:last-child
        min-height: 44px
        &:before, &:after
          border-bottom: none

=box_hover
  ul li:hover
    background: $grey_f2

.flex-center
  display: flex
  align-items: center
