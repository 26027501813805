@import '../common'

#alert .alert_block
  display: none

#alert, .alert_preview
  .alert_block
    color: $black
    background: $white
    .alert_pager
      border-color: $grey
      .prev, .next
        color: $black
    .alert
      @extend %clearfix
      padding: 0 10px
      background: $white
      .message, .separator
        color: $black
      .alert_level
        color: #ff0000
        &.new
          color: $black
        &.sale
          color: $black
      .message
        color: $black
        a
          color: $link_color
          &:hover
            color: $link_color

#alert
  background: $white
  font-size: 16px
  overflow: hidden
  border-bottom: 1px solid $grey

  .space
    display: inline-block

  .alert_block
    padding-top: 18px
    padding-bottom: 16px
    background: $white
    vertical-align: top

    .alert_pager
      @extend %grid1
      white-space: nowrap
      text-align: center

      .prev, .next, .counter
        display: inline-block

    .alert_body
      @extend %grid10

    .alert_level
      font-family: Arial, Helvetica, sans-serif
      font-weight: bold
      text-transform: uppercase

    .close
      @extend %grid1
      float: right
      text-align: right
      font-size: 16px
      margin-top: 2px
      a
        color: $green
        &:hover
          color: $lgreen
