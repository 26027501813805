$xs: 480px
$sm: 640px
$md: 768px
$lg: 1024px
$xl: 1320px

.flex-row
  display: flex

.flex-row .flex-col
  flex: 0 0 100%

.flex-col
  flex: 1

@media (min-width: $xs)
  .flex-row-xs
    margin: 0 -10px 0 -10px
    > .flex-col
      padding: 0 10px 0 10px
      flex: 1

@media (min-width: $sm)
  .flex-row-sm
    margin: 0 -10px 0 -10px
    > .flex-col
      padding: 0 10px 0 10px
      flex: 1

@media (min-width: $md)
  .flex-row-md
    margin: 0 -10px 0 -10px
    > .flex-col
      padding: 0 10px 0 10px
      flex: 1

@media (min-width: $lg)
  .flex-row-lg
    margin: 0 -10px 0 -10px
    > .flex-col
      padding: 0 10px 0 10px
      flex: 1

@media (min-width: $xl)
  .flex-row-xl
    margin: 0 -10px 0 -10px
    > .flex-col
      padding: 0 10px 0 10px
      flex: 1
