@import '../common'

.service_icons
  .icon, .more
    padding-top: 10px
    padding-bottom: 10px

  .ico
    width: 60px
    height: 60px
    background-image: url("../../../images/home/homepage/services.png")
    background-repeat: no-repeat
    background-size: auto 60px
    border: 2px solid transparent

    &.border
      border-color: $grey

  .more
    font-size: 18px
    font-weight: 600
    padding-top: 20px

