@import '../common'

form.search
  position: relative
  width: 100%
  max-width: 620px
  margin: 0 auto

  input[type=text]
    vertical-align: top
    height: 60px
    margin-right: 60px
    width: 100%
    max-width: 560px
    border: 2px solid $grey
    border-right: 0
    font-size: 22px
    font-family: 'Inter'
    color: $black
    padding: 0 20px
    @include placeholder-color($dgrey)

  @media screen and (max-width: $mobile_max)
    max-width: 300px
    margin: 0 auto
    text-align: center

    input[type=text]
      max-width: 300px
      font-size: 18px
      margin: 0

  button
    position: absolute
    top: 0
    right: 0
    vertical-align: top
    width: 60px
    height: 60px
    font-size: 28px
    text-align: center
    padding: 0

.right form.search

  input
    margin: 0
    height: 45px
    font-size: 16px
    border: 1px solid $grey

  button
    width: 45px
    height: 45px
    line-height: 45px

form.search.transfer
  max-width: none

  input[type=text]
    max-width: none
