@import '../common'

.home.tos
  // h1
  //   font: 600 40px 'Inter'
  //   margin-bottom: 50px

  .left
    +grid(8,12)

  .right
    +grid(4,12)

  .left
    text-align: left

    h2
      font: 500 22px 'Inter'

    .tosbody
      margin-top: 10px

  .right
    padding-top: 20px
