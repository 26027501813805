@import '../common'

.domain_pricing
  text-align: center

  .table
    @extend %grid9
    margin-top: 35px
  .sidebar
    @extend %grid3
    margin-top: 35px

  form
    text-align: left
    height: 45px
    padding: 5px 0
    width: 100%
    text-transform: uppercase
    background: $white
    border-top: 1px solid $grey
    input
      vertical-align: top
      height: 35px
      border: 0
      padding-left: 10px
      margin: 0
      text-transform: uppercase
      border: 1px solid $grey
      width: 100%
      +placeholder-color($black)
    button
      vertical-align: top
      width: 35px
      height: 35px
      padding: 0
    select
      text-transform: uppercase
      font-size: 12px
      font-weight: 600

  .filter
    float: left
    width: 40%
    position: relative
    padding-left: 0px
    i
      position: absolute
      top: 9px
      left: 8px
    input
      padding-left: 30px

  label.bulk
    float: left
    width: 60%
    padding-right: 0
    text-align: right
    font: 600 12px/12px 'Inter'
    text-transform: uppercase
    span, select
      vertical-align: top
    span
      display: inline-block
      margin-top: 13px
    .selectmenu
      margin-left: 6px
      padding: 7px 0 0 5px
      width: 130px
      height: 35px
      line-height: 29px
      select
        line-height: 12px

      // Firefox only: valign select inside .selectmenu
      // http://browserstrangeness.bitbucket.org/css_hacks.html
      _:-moz-tree-row(hover), select
        padding-top: 3px
        padding-left: 0px

  table
    width: 100%

    tr:hover td
      background: #f5f5f5

    th
      border-top: 2px solid $black

    td
      border-bottom: 1px solid $grey

    th.highlight, td.highlight
      border-left: 2px solid $lgreen
      border-right: 2px solid $lgreen

    th.highlight
      border-top: 2px solid $lgreen
    tbody tr:last-child td.highlight
      border-bottom: 2px solid $lgreen

    td, th
      padding: 12px
      width: 20%
      min-width: 140px
      &:first-child
        width: 40%
        text-align: left

    th:first-child
      padding-top: 2px
      padding-bottom: 0
      a
        display: inline-block
        line-height: 39px
        margin-right: 12px

        &:hover
          color: $lgreen

        &.active
          color: $lgreen
          border-bottom: 2px solid $lgreen

    th
      padding: 14px
      font: 600 12px/12px 'Inter'
      text-transform: uppercase
      border-left: 1px solid #575e65

      background: $black
      color: $white
      &:first-child
        border-left: none


    td:first-child
      text-align: left

  .sidebar
    aside
      border: 1px solid $grey
      padding: 20px
      margin-bottom: 20px
      .ico
        opacity: 0.5
      h1
        font: 600 24px 'Inter'
        margin-bottom: 10px
      p
        margin-top: 10px
      .note
        font-size: 12px

  @media screen and (max-width: $tablet_max)
    .table
      float: none
      width: 100%
      margin-top: 35px

    .sidebar
      width: 100%
      margin-top: 35px

      aside
        vertical-align: top
        display: inline-block
        max-width: 300px
        margin: 10px

  @media screen and (max-width: 639px)
    form
      height: auto
    .filter, label.bulk
      padding-left: 14px
      padding-right: 14px
      float: none
      display: block
      text-align: center
      width: 100%
    .bulk
      height: 35px
      margin-top: 5px
    .selectmenu
      display: inline-block
    .filter i
      left: 22px

    table
      border-top: 1px solid $grey

      td.unselected, th.unselected
        display: none

      tr th, tr th:first-child
        background: $white
        color: $black
        border: none
        border-bottom: 1px solid $grey
        height: 45px
        text-align: center

        &:first-child
          padding-top: 15px

          label
            margin-right: 10px
          a.active
            color: $black
            border-color: $black

          a
            color: $link_color
            line-height: 18px

      td
        text-align: right

        &:first-child
          text-align: left

    .sidebar aside
      display: block
      margin: 10px auto
