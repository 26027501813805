@use 'sass:math'
@import '../common'

.homepage
  section.hero form.search
    margin-top: 30px

  .tout
    padding-top: 15px

  .services
    padding-bottom: $section_padding - 10px

  .connect
    .plus_hover
      margin-top: 25px
      font-size: 0

      span
        display: inline-block
        width: 60px
        font-size: 40px
        vertical-align: top
        margin-top: 45px
        font-weight: 600

      .hover_logo
        display: inline-block
        vertical-align: top
        width: 140px
        height: 140px
        padding-top: 53px
        background: $black

        img
          width: 100px

      .happiness
        display: inline-block
        vertical-align: top
        width: 140px
        height: 140px
        padding-top: 4px
        background: #fff02a
        color: $black
        font-size: 96px

  .blog_posts li
    h2
      color: $link_color

    &:hover
      cursor: pointer
      h2
        color: $link_hover

    .site
      color: $black

  .we_support
    .logos
      margin-top: 20px

    .logo
      margin: 10px
      vertical-align: middle
      opacity: 0.5


  .service_icons
    @extend %clearfix
    width: 100%
    max-width: 270px
    margin: auto

    .icon
      +grid(1,3)
    .more
      +grid(3,3)

  @media screen and (min-width: 360px)
    .service_icons
      max-width: 320px
      .icon
        +grid(1,4)
      .more
        +grid(2,4)

  @media screen and (min-width: 480px)
    .service_icons
      max-width: 480px
      .icon
        +grid(1,6)
      .more
        +grid(2,6)

  @media screen and (min-width: 420px)
    .service_icons
      max-width: 400px
      .icon
        +grid(1,5)
      .more
        +grid(2,5)

  @media screen and (min-width: 768px)
    .service_icons
      max-width: 640px
      .icon
        +grid(1,8)
      .more
        +grid(2,8)

  @media screen and (min-width: 1024px)
    .service_icons
      max-width: none
      margin-left: math.div(100%, 12)
      margin-right: math.div(100%, 12)
      width: math.div(100% * 10, 12)
      margin-top: 25px
      .icon
        +grid(1,10)
      .more
        +grid(2,10)
