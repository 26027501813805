@import '../common'
.tweets
  padding: 20px

.tweet
  position: relative
  text-align: left
  padding-left: 60px
  padding-bottom: 40px

  img
    position: absolute
    top: 0
    left: 0

  .name
    margin-top: 16px

  .name, .handle
    font-size: 12px
    text-transform: uppercase
