@import '../common'

$lgrey: #e5e5e5

.results
  .minicart
    .note
      background: #d6e99a
      transition: all 500ms
      max-height: 103px
      padding: 20px
      margin-bottom: 20px

    +fade-slide(note)
      max-height: 103px
      padding: 20px
      margin-bottom: 20px

    h2
      margin-bottom: 0
      height: 45px
      width: 100%
      margin: 0
      padding-top: 17px
      padding-left: 20px
      text-align: left
      font-weight: 500
      background: $lgrey
      color: $black
      +caps

    .cart
      border: 1px solid $lgrey
      border-top: none
      padding: 20px
      padding-top: 0

    li, .cart_total, .summary_items
      +clearfix
      border-bottom: 1px solid $lgrey
      font-size: 16px
      &.is_last
        border-bottom: none
        padding-bottom: 0

    li
      transition: all 500ms
      overflow-y: hidden

    +fade-slide(cart-item)
      padding: 19px 0 16px
      max-height: 100px

    .row
      +clearfix

    .cart_total, .summary_items
      padding: 20px 0 17px

    .cart_total
      font-weight: 600
      .label
        float: left

      .amount
        float: right
      .amount
        font-size: 24px
      .label
        +caps
        padding-top: 10px

    .cart_summary_item
      display: flex
      justify-content: space-between
      padding: 5px 0

      .label, .amount
        float: none
      .amount
        white-space: nowrap

    .buttons
      padding: 20px 0 0
      text-align: center

    .sub
      padding-top: 10px
      font-size: 14px
      .row
        display: flex
        justify-content: space-between
        +noclearfix


        .amount
          display: flex
          align-items: center
          .slash
            padding: 0 3px

    .remove_cart
      background: transparent
      border: none
      padding: 0
      color: $green
      font-size: 16px
      &:hover
        color: $black

    li
      position: relative
      overflow-x: hidden
      .remove_cart
        position: absolute
        top: 8px
        right: -14px
        display: block
        width: 40px
        height: 40px

    .note
      background: $greenbg

    .cart-item.minicart-item
      .name
        display: flex
        align-items: center

    .bundle_offer_label
      background-color: $bundle_orange
      color: #ffffff
      display: inline-block
      padding: 2px 6px
      margin-left: 10px
      text-transform: uppercase
      font-size: 10px
      font-weight: 600
      line-height: 14px

@media screen and (max-width: 640px)
  .results
    .minicart
      border: 0
      margin: 0

      .note, h2, ul, .cart_total
        display: none

      .buttons
        position: fixed
        bottom: 0
        left: 0
        height: 45px
        width: 100%

        border: 0
        margin: 0
        padding: 4px
        text-align: center
        background: $green
        color: #fff
        -webkit-animation: slideup 0.6s
        animation: slideup 0.6s

        a.button
          display: inline
          border: none
          margin: 0
          padding: 0
          background: none
          color: #fff
          font-size: 16px
          white-space: nowrap
