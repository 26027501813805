@import '../common'

.selectmenu
  display: inline-block
  background: $white
  overflow: hidden
  position: relative
  text-align: left
  line-height: 0
  border: 1px solid $grey

  &:after
    position: absolute
    top: 4px
    right: 10px
    font-family: "Font Awesome 6 Pro"
    font-weight: 900
    font-size: 10px
    content: "\f078"  // chevron-down
    color: #229E87
    pointer-events: none

  select
    padding: 5px
    line-height: normal
    border: 0
    border-radius: 0
    width: 100%
    background: white
    -webkit-appearance: none
    -moz-appearance: none
    font-family: 'Inter'
    font-size: 14px
    font-weight: 400

    &:focus
      outline: 0

.selectbox
  $height: 40px
  display: inline-block
  background: $white
  height: $height
  overflow: hidden
  position: relative
  text-align: left
  line-height: $height
  padding-left: 5px
  border: 1px solid $grey

  &:after
    position: absolute
    top: -1px
    right: -1px
    display: block
    width: $height + 2px
    height: $height + 2px
    border: 1px solid $grey
    text-align: center
    line-height: $height
    font-family: "Font Awesome 6 Pro"
    font-weight: 900
    font-size: 22px
    content: "\f0d7"  // caret-down
    color: #229E87
    pointer-events: none

  select
    padding: 5px
    line-height: normal
    border: 0
    border-radius: 0
    width: 110%
    background: white
    -webkit-appearance: none
    -moz-appearance: none
    font-family: 'Inter'
    font-size: 16px
    font-weight: 400

    &:focus
      outline: 0
