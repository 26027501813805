@import '../common'

.features
  @extend %clearfix
  margin-top: 30px
  margin-bottom: -50px // cancel out last row
  display: flex
  display: -webkit-flex
  flex-wrap: wrap
  -webkit-flex-wrap: wrap
  justify-content: center
  -webkit-justify-content: center
  padding-left: 20px
  padding-right: 20px

  li, .feature
    margin-top: 20px
    .ico
      height: 64px
      opacity: 0.5

    .tagged-icon
      width: 64px
      margin: 0 auto 6px
      position: relative

      $tag_bg: #f26522

      .icon-tag:not(:empty)
        position: absolute
        top: 10px
        left: 62px
        background: $tag_bg
        height: 20px
        padding: 0 10px 0 7px
        color: #fff
        font: 500 10px "Inter"
        line-height: 20px

        &::before
          background: transparent
          border-bottom: 10px solid transparent
          border-right: 10px solid $tag_bg
          border-top: 10px solid transparent
          content: ''
          position: absolute
          left: -10px
          top: 0


  li:first-child
    padding-left: 0
  li:last-child
    padding-right: 0

  h2
    margin-top: 15px
    font-weight: 600
    font-size: 20px
    line-height: 1.3em

    a
      line-height: 1em

  button, a.btn
    margin-top: 20px

  p.deck
    margin-top: 6px

  .site
    margin-top: 12px
    text-transform: uppercase
    font-size: 12px

  .cta
    display: block
    margin-top: 12px
    text-transform: uppercase
    font-size: 12px
    font-weight: 600

  > li, .feature
    margin-bottom: 50px

@media screen and (max-width: $mobile_max)
  .features
    .feature
      margin-top: 0
