@mixin transition($cmds...)
  -moz-transition: $cmds
  -webkit-transition: $cmds
  -ms-transition: $cmds
  -o-transition: $cmds
  transition: $cmds

@mixin transform($transforms...)
  -moz-transform: $transforms
  -o-transform: $transforms
  -ms-transform: $transforms
  -webkit-transform: $transforms
  transform: $transforms
