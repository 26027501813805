@import '../common'

%btn
  +btn

%btn-primary
  +btn-primary

%btn-link
  +btn-link

button.btn, a.btn
  +btn
  &.primary, &.btn-primary
    +btn-primary
  &.btn-danger
    +btn-danger

a.btn
  display: inline-block

button.btn-link
  +btn-link
  &.cancel
    font-size: 12px
    font-weight: 600
    text-transform: uppercase

.btn.btn-quick-select
  color: $black
  background: $white
  border: 1px solid $grey
  text-transform: none
  font-weight: normal
  font-size: inherit
  padding: 10px 20px
  &:hover:not(:disabled), &.btn-selected
    color: $black
    background: $white
    border: 2px solid $green
    padding: 9px 20px 9px
